@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media print {
  html, body {
    width: 100%!important;
    height: 100%!important;
  }
}

/*fonts*/

body {
  font-family: "Manrope", sans-serif;
}

.dashboard__main-container {
  display: flex;
  width: 100%;
}

.d__container {
  width: 100%;
}

.dashboard_container {
  padding: 50px;
  background-color: #f1f5f9;
}

.d__title {
  margin-bottom: 15px;
  font-weight: 800;
  font-size: 13.9494px;
  line-height: 19px;
  color: #334145;
}

.cta__description {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #22333b;
  margin-bottom: 23px;
}

.button__flex {
  display: flex;
}

.big__col {
  width: 58%;
  padding-left: 17px;
}

.heading {
  border-bottom: 1px solid #e2e2e2;
}

table {
  width: 100%;
  margin-top: 50px;
  border: 1px solid #e2e2e2;
  margin-bottom: 45px;
}

thead {
  border-bottom: 1px solid #e2e2e2;
  padding-left: 80px;
}

th {
  padding-top: 24px;
  padding-bottom: 24px;
}

.small__col {
  width: 20%;
  text-align: left;
  vertical-align: baseline;
}

.xs__col {
  width: 2%;
  text-align: center;
  border-right: 1px solid #e2e2e2;
}

.input__cta {
  width: 80%;
  padding: 9px 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.17);
  border-radius: 4px;
  border: none;
}

button {
  color: #0284c7;
  border-color: #0284c7;
  padding: 4px 11px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  -webkit-filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
  border-radius: 4px;
  border: 1px solid #0284c7;
}

.heading-button {
  margin-left: 52%;
}

.blue-btn {
  background: #0284c7;
  color: #fff;
}

.download-icon {
  font-size: 25px;
  margin-right: 10px;
}

.download-icon-expert {
  font-size: 25px;
  margin-right: 10px;
  color: #ef4444;
}

.MuiDataGrid-cell {
  overflow-x: scroll !important;
}

.css-1wc848c-MuiFormHelperText-root{
  color:red!important;
}

/* idarb details */
.idarb__email {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
  color: #dd524c;
  margin-bottom: 35px;
}

/* judicial documents */

.instructions{
  color:#334145;
  margin-top:0!important;
  margin-bottom:20px!important;
}

.judicial-case__title{
  margin:0;
}

.file-margin{
  margin-right:10px;
}

.title__input{
  margin-top:20px;
  display:flex;
  align-items:center;
}

.title__input div{
  margin-right:20px;
}

.buttons button:nth-child(1){
  margin-right:20px;
}

.add__container button{
  margin-left:20px;
}

.add__container{
  font-size:25px;
  font-weight:700;
  margin-top:35px;
}

.overlay{
  background: rgba(23, 25, 31, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete__modal{
  position:relative;
  background: #ffffff;
  border-radius: 16px;
  color: black;
  width: 28.47%;
  padding: 30px;
}

.delete__modal svg{
  position:absolute;
  top:10px;
  right:10px;
  cursor:pointer
}

.button__flex{
  display:flex;
  align-items:center;
  margin-top:30px;
}

.button__flex button{
  margin-right:30px;
}

/* judicial texts */



.judicial__texts-container .MuiOutlinedInput-root{
  margin-bottom:20px!important;
}


